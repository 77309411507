// This component is best used on a modal
<template v-if="selectedInvestment">
  <div>
    <p class="text-gray-500">Investment Name</p>
    <div class="text-xl font-bold">
      {{ investmentName }}
    </div>

    <p class="text-gray-500">Tenure in Days</p>
      <div class="text-xl font-bold">
        {{ investmentDays }}
      </div>

    <p class="text-gray-500 mt-2">Investment Amount</p>
    <div class="bg-blue-200 rounded p-2 text-center mb-10">
      <div class="text-xl font-bold">
        ₦{{ investmentAmount | currency }}
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['selectedInvestment'],
    computed: {
      investmentName() {
        return this.selectedInvestment?.name;
      },
      investmentDays() {
        return this.selectedInvestment?.days;
      },
      investmentAmount() {
        return this.selectedInvestment?.amount;
      }
    }
  }
</script>